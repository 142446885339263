import React, { useMemo } from 'react';
import { Grid, Dialog, DialogContent, Box, Typography, IconButton } from '@mui/material';
import { Formik, Form, FormikProps } from 'formik';
import useLocales from 'hooks/useLocales';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import FMTextInput from 'components/reusable/formik-inputs/FMTextInput';
import { partiesInitialValues } from 'constants/initialValues/partiesInitialValues';
import useAPIError from 'hooks/useAPIError';
import { Button } from '@mui/material';
import { partyValidationSchema } from 'constants/validations/partyValidationsSchemas';
import { useNavigate } from 'react-router';
import { useCreatePartyMutation, useUpdatePartyByUUIDMutation } from 'redux/api/partyAPI';
import { IPartyItem } from '../../@types/party';
import { CloseIcon } from 'theme/overrides/CustomIcons';

type Props = {
  data?: IPartyItem
  onClose: () => void;
  open: boolean;
  isEdit: boolean;
  partyType: "Own organization" | "Counter party"
}

function PartyModel({ data, open, onClose, partyType, isEdit }: Props) {
  const partyModalType = partyType === "Counter party" ? 1 : 2

  const { translate } = useLocales()
  const { handleError } = useAPIError()
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [updatePartyByUUIDMutation, { error: errorUpdate, isSuccess, isLoading: isLoadingUpdate }] = useUpdatePartyByUUIDMutation()
  const [createParty, { error: errorCreate, isSuccess: isSuccessCreate, isLoading: isLoadingCreate }] = useCreatePartyMutation()

  const error = errorUpdate || errorCreate
  const isLoading = isLoadingUpdate || isLoadingCreate

  useMemo(() => error && handleError(error), [error, handleError])
  useMemo(() => isSuccess && enqueueSnackbar(translate("common.updated", "Updated"), { variant: 'success' }), [enqueueSnackbar, isSuccess, translate])
  useMemo(() => isSuccessCreate && enqueueSnackbar(translate("common.Created", "Created"), { variant: 'success' }), [enqueueSnackbar, isSuccessCreate, translate])

  const handleSubmit = async (formDataValues: any) => {

    const formData = { ...formDataValues }
    try {

      if (formData.partyRole === 2) {
        formData.aliases = formData.aliases?.map((e: any) => {
          if (!e.uuid) {
            return { ...e, id: null, uuid: null }
          }
          return e
        })
      } else {
        formData.aliases = []
      }

      formData.partyRole = {
        name: formData.partyRole === 1 ? "Counterparty" : "Own organization",
        id: formData.partyRole
      }

      if (isEdit) {
        await updatePartyByUUIDMutation({ uuid: data?.uuid!, data: formData }).unwrap()
      } else {
        await createParty({ data: formData }).unwrap()
      }
      onClose()
    } catch (error) {
      handleError(error)
    }
  }

  const initialValues = partiesInitialValues(data, partyModalType)

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogContent>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={partyValidationSchema(translate)}
          enableReinitialize
        >
          {(formik: FormikProps<any>) => {
            return <Form >
              <Grid container spacing={3}>
                <Grid item container md={12} spacing={2} alignContent={"start"}>
                  <Grid item md={12}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >

                      <Typography variant="subtitle1">
                        {partyType === "Counter party" ? translate('party.createCounterParty', 'Create counter party') : translate('party.ownOrganizationParty', 'Own organization party')}
                      </Typography>

                      <IconButton onClick={() => onClose()}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item md={12}>
                    <FMTextInput name="name" label={translate("parties.name", "Name")} disabled={isLoading} />
                  </Grid>
                  <Grid item md={12}>
                    <FMTextInput name="number" label={translate("parties.organizationId", "Organization Id")} disabled={isLoading} />
                  </Grid>

                  <Grid item md={12}>
                    <LoadingButton type='submit' loading={isLoading} variant='contained'>{translate("common.submit", "Submit")}</LoadingButton>
                    <Button
                      onClick={() =>
                        navigate(-1)
                      }
                      variant="outlined"
                      sx={{ ml: 1 }}
                    >{translate('controls.cancel', 'Cancel')}
                    </Button>

                  </Grid>
                </Grid>
              </Grid>
            </Form>
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

export default PartyModel;
