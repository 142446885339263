import { IconButton, TableCell } from '@mui/material'
import { IPartyListItemWithPermission } from '../../../../@types/party'
import { Fragment, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router'
import { PATH_COUNTER_PARTIES } from '../../../../routes/paths'
import Iconify from 'components/Iconify'
import { Menu } from '@mui/material'
import { MenuItem } from '@mui/material'
import useLocales from 'hooks/useLocales'
import Popup from 'layouts/popup/popup'
import { useDeletePartyByUUIDMutation } from 'redux/api/partyAPI'
import { useSnackbar } from 'notistack'
import useAPIError from 'hooks/useAPIError'
import { FormatDate } from 'utils/format-date'

interface Props extends IPartyListItemWithPermission {

}

const PartyRow = ({ name, number, uuid, id, permission, contractCount, createdAt, lastModifiedAt }: Props) => {
    const navigate = useNavigate()
    const { translate } = useLocales()
    const inputEl = useRef(null);
    const { handleError } = useAPIError()
    const { enqueueSnackbar } = useSnackbar();

    const [deleteParty, { error, isSuccess }] = useDeletePartyByUUIDMutation()

    useMemo(() => error && handleError(error), [error, handleError])
    useMemo(() => isSuccess && enqueueSnackbar(translate("common.deleted", "Deleted"), { variant: 'success' }), [enqueueSnackbar, isSuccess, translate])

    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);

    const deleteFunction = async () => {
        try {
            await deleteParty({ uuid: uuid })
            setOpenPopup(false)
            setOpen(false)
        } catch (error) {
            console.log(error);
        }

    }


    return (
        <Fragment>
            <TableCell
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`${PATH_COUNTER_PARTIES.view.replace(':id', uuid?.toString())}`)}
                className="row_link"
            >
                {name}
            </TableCell>
            <TableCell>{number}</TableCell>
            <TableCell>{contractCount.toString()}</TableCell>
            <TableCell>{FormatDate(new Date(createdAt))}</TableCell>
            <TableCell>{FormatDate(new Date(lastModifiedAt))}</TableCell>
            <TableCell width={30}>
                <div ref={inputEl}>
                    <IconButton onClick={() => setOpen((val) => !val)}>
                        <Iconify icon={'eva:more-vertical-fill'} width={20} height={20} />
                    </IconButton>
                    <Menu open={open} anchorEl={inputEl.current} onClose={() => setOpen((val) => !val)}>
                        <MenuItem disabled={permission?.permissionName !== 'Editor'} onClick={() => setOpenPopup(true)}>
                            <Iconify
                                icon={'eva:trash-2-outline'}
                                sx={{ marginRight: 1, fontSize: '1.5rem', color: 'error.main' }}
                            />
                            {translate('common.delete', 'delete')}
                        </MenuItem>
                        <MenuItem onClick={() => navigate(`${PATH_COUNTER_PARTIES.view.replace(':id', uuid?.toString())}`)} >
                            <Iconify icon={'eva:edit-fill'} sx={{ marginRight: 1, fontSize: '1.5rem' }} />

                            {translate('common.edit', 'edit')}
                        </MenuItem>
                    </Menu>
                </div>
            </TableCell>
            <Popup
                open={openPopup}
                title={translate('warnings.deleteParties', 'Are you sure want to delete this party?')}
                onClose={() => {
                    setOpen(false);
                    setOpenPopup(false);
                }}
                deleteFunction={deleteFunction}
            />
        </Fragment>
    )
}

export default PartyRow