import CustomTable from 'layouts/custom-table/custom-table';
import PartyRow from './rows/PartyRow';
import useLocales from 'hooks/useLocales';
import { partyColumns } from 'constants/initialValues/counterPartiesInitialValues';
import { IPartyListDetails, IPartyListItem, IPartyListItemWithPermission } from '../../../@types/party';
import { useMemo, useState } from 'react';
import useAPIError from 'hooks/useAPIError';
import { useSnackbar } from 'notistack';
import { useDeletePartyByUUIDMutation } from 'redux/api/partyAPI';


type Props = {
    data: IPartyListDetails | undefined
    isLoading: boolean
}

const CounterPartiesList = ({ data, isLoading }: Props) => {
    const { translate } = useLocales()
    const [selected, setSelected] = useState([])
    const { handleError } = useAPIError()
    const { enqueueSnackbar } = useSnackbar();

    const [deleteParty, { error, isSuccess }] = useDeletePartyByUUIDMutation()

    useMemo(() => error && handleError(error), [error, handleError])
    useMemo(() => isSuccess && enqueueSnackbar(translate("common.deleted", "Deleted"), { variant: 'success' }), [enqueueSnackbar, isSuccess, translate])

    const handleDelete = async (ids: any) => {
        try {
            const idArray = ids.split(",")

            const filterItems = data?.content?.filter(e => idArray.some((id: string) => +id === e.id))
            const selectedItmesUUIDAsString = filterItems?.map(e => e.uuid).join(",")!
            await deleteParty({ uuid: selectedItmesUUIDAsString })
        } catch (error) {
            console.log(error);
        }

    }

    const rows = data?.content?.map((e: IPartyListItemWithPermission) => {

        return {
            ...e, permission: {
                permissionName: e.contractCount ? "View" : "Editor"
            }
        }
    })
    return <CustomTable
        headers={partyColumns(translate)}
        rowsData={data ? rows : []}
        handleDelete={handleDelete}
        SingleRow={PartyRow}
        allowMultiSelect={true}
        isLoading={isLoading}
        totalRecords={data?.pagination?.totalRecords}
        selected={selected}
        setSelected={setSelected}
        stickyHeader={true}
    />
}

export default CounterPartiesList