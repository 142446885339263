import * as Yup from 'yup';

export const contactPersonValidationSchema = (translate: any) => Yup.object().shape({
    firstName: Yup.string()
        .required(translate('validations.user.firstName', 'First name is required.'))
        .max(30, translate('validations.user.max', 'Maximum length for the first name is 30.'))
        .min(2, translate('validations.user.min', 'Minimum length for the first name is 2.')),
    lastName: Yup.string()
        .required(translate('validations.user.lastName', 'Last name is required'))
        .max(30, translate('validations.userLastName.max', 'Maximum length for the last name is 30.'))
        .min(2, translate('validations.userLastName.min', 'Minimum length for the last name is 2.')),
});
